import React, { useEffect, useState } from 'react';
import { RouteComponentProps, Link, navigate } from '@reach/router';
import Page from '../components/Page';

export default (props: RouteComponentProps) => {
  return <Page>
    <div>
      <h1 className="">This is the home, you can sleep here</h1>
      <Link to="../"><button>Back</button></Link>
      <button onClick={
        event => {
          event.preventDefault()
          console.log("back button is clicked")

          // pretend like we saved a record to the DB here
          // and then we navigate imperatively
          navigate(-1)
        }}
      >
        Back</button>
    </div>

  </Page>;
};
