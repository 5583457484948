import React from 'react';
import { Router, navigate } from '@reach/router';
import { StoreProvider } from './store';


//import pages
import AboutUs from './pages/about_us';
import EditAccount from './pages/account_edit';
import Account from './pages/account';
import Cart from './pages/cart';
import Checkout from './pages/checkout';
import Collection from './pages/collection';
import ContactUs from './pages/contact_us';
import FAQ from './pages/faq';
import Home from './pages/home';
import Index from './pages/index';
import Login from './pages/login';
import Logoff from './pages/logoff';
import NewIn from './pages/new_in';
import Product from './pages/product';
import ReturnPolicy from './pages/return_policy';
import TermsCondtion from './pages/terms_conditions';
import TrackOrder from './pages/track_order';
import NotFound from "./pages/not_found"





function App() {
  return (
    <StoreProvider>
      <Router>
        <Index path="/" />

        <Home path="home" />


        <NewIn path="new-in" />
        <Collection path="collection/:ID" />
        <Product path="product/:ID" />

        <Checkout path="checkout" />
        <Cart path="cart" />

        <Login path="login" />
        <Logoff path="logout" />

        <Account path="account" >
          <EditAccount path="edit" />
        </Account>



        <TermsCondtion path="terms-and-conditions" />
        <ReturnPolicy path="return-policy" />
        <TrackOrder path="track" />
        <FAQ path="faq" />
        <AboutUs path="about-us" />
        <ContactUs path="contact-us" />
        <NotFound default />

      </Router>
    </StoreProvider>
  );
}

export default App;
