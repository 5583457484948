import React, { Children } from 'react';
import { RouteComponentProps, Link, navigate } from '@reach/router';
import Page from '../components/Page';

export default (props: RouteComponentProps) => {
  return <Page>
    <div>
      <h1>Edit my profile</h1>
      <Link to="../">
        <button>Back</button>
      </Link>

    </div>
  </Page>;
};
