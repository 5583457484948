import React, { createContext, ReactChild, useReducer, Dispatch } from 'react';

export interface UserState {
  loading: boolean;
  error?: string | null;
  user?: any | null;
}

export enum UserAction {
  SignIn = `USER_SIGN_IN`,
  SignInSuccess = `USER_SIGN_IN_SUCCESS`,
  SignInFail = `USER_SIGN_IN_FAIL`,
  SignOut = `USER_SIGN_OUT`,
  SignOutSuccess = `USER_SIGN_OUT_SUCCESS`,
  SignOutFail = `USER_SIGN_OUT_FAIL`,
}

const initState: UserState = {
  loading: false,
  error: null,
  user: null,
};

export const UserContext = createContext<{
  state: UserState;
  dispatch: Dispatch<any> | null;
}>({ state: initState, dispatch: null });

const reducer = (state: UserState, action: any) => {
  const actionType = action.type as UserAction;
  switch (actionType) {
    case UserAction.SignIn:
      return { ...state, loading: true, user: null, error: null };

    case UserAction.SignInSuccess:
      return { ...state, loading: false, user: action.user, error: null };

    case UserAction.SignInFail:
      return { ...state, loading: false, user: null, error: action.error };

    case UserAction.SignOut:
      return { ...state, loading: true };

    case UserAction.SignOutSuccess:
      return { ...state, loading: false, user: null, error: null };

    case UserAction.SignOutFail:
      return { ...state, loading: false, error: action.error };

    default:
      return state;
  }
};

export const UserProvider = ({
  children,
}: {
  children: ReactChild | ReactChild[];
}) => {
  const [state, dispatch] = useReducer(reducer, initState);
  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};
