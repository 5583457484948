import React, { useEffect, useState } from 'react';
import { Link, navigate } from '@reach/router';
import styled from 'styled-components';

const activeStyle = {
  color: '#1cde78'
};

export default () => {


  // useEffect(() => {

  // }, []);

  const Show = (params: any) => {

  };

  const [showMenu, setMenuView] = useState(false);

  const [showMenuContent, setMenuContentView] = useState(false);

  const [showCollectionMenu, setCollectionMenuView] = useState(false);
  const [showCategoryMenu, setCategoryMenuView] = useState(false);
  const [showAboutUsMenu, setAboutUsMenuView] = useState(false);

  const toggle = (toggle_target: any) => {
    console.log(toggle_target)

    if (toggle_target === "menu") {
      setMenuView(!showMenu);
    }

    if (toggle_target === "collection") {
      setCollectionMenuView(!showCollectionMenu)
    }

    if (toggle_target === "category") {
      setCategoryMenuView(!showCategoryMenu);
    }

    if (toggle_target === "aboutus") {
      setAboutUsMenuView(!showAboutUsMenu);
    }

    if (toggle_target === "null") {
      setMenuContentView(!showMenuContent);
    }

    // case "category": setCategoryMenuView(!showCategoryMenu);
    // case "aboutus": setAboutUsMenuView(!showAboutUsMenu);
    // default: setMenuContentView(!showMenuContent);

    console.log(showCollectionMenu, showCategoryMenu, showAboutUsMenu, showMenuContent)
  }

  const DisplayMenuContent = (toggle_display: any) => {
    if (toggle_display === "collection") {
      return (
        <MenuContent>
          <StyledLink to="collection/s-rocker">S.Rocker</StyledLink>
          <StyledLink to="collection/s-rocker">S.Rocker</StyledLink>
          <StyledLink to="collection/s-rocker">S.Rocker</StyledLink>
        </MenuContent>
      )
    }
    if (toggle_display === "category") {
      return (
        <MenuContent>
          <StyledLink to="collection/s-rocker">T-shirt</StyledLink>
          <StyledLink to="collection/s-rocker">Hoodie & sweater</StyledLink>
          <StyledLink to="collection/s-rocker">accessories</StyledLink>
        </MenuContent>
      )
    }
    if (toggle_display === "aboutus") {
      return (
        <MenuContent>
          <StyledLink to="collection/s-rocker">T-shirt</StyledLink>
          <StyledLink to="collection/s-rocker">Hoodie & sweater</StyledLink>
          <StyledLink to="collection/s-rocker">accessories</StyledLink>
        </MenuContent>
      )
    }
  }

  const DisplayMenu = () => {
    return (
      <Menu>
        <Section>
          <div>Close</div>
        </Section>
        <Section>
          <SectionHeader>
            <MenuTitle onClick={event => {
              event.preventDefault()
              console.log("call toggle function")

              // pretend like we saved a record to the DB here
              // and then we navigate imperatively
              toggle("collection");

            }}>
              <div>Collection</div>
              <div>{showCollectionMenu ? "-" : "+"}</div>
            </MenuTitle>
            {showCollectionMenu ?
              DisplayMenuContent("collection") : ''}
          </SectionHeader>
          <SectionHeader>
            <MenuTitle>
              <div>Gallery</div>
            </MenuTitle>
          </SectionHeader>
          <SectionHeader>
            <MenuTitle onClick={event => {
              event.preventDefault()
              console.log("call toggle function")

              // pretend like we saved a record to the DB here
              // and then we navigate imperatively
              toggle("category");

            }}>
              <div>Category</div>
              <div>{showCategoryMenu ? "-" : "+"}</div>
            </MenuTitle>
            {showCategoryMenu ?
              DisplayMenuContent("category") : ''}
          </SectionHeader>
        </Section>
        <Section>
          <SmallSectionHeader>
            <MenuTitle onClick={event => {
              event.preventDefault()
              console.log("call toggle function")

              // pretend like we saved a record to the DB here
              // and then we navigate imperatively
              toggle("aboutus");

            }}>
              <div>About Us</div>
              <div>{showAboutUsMenu ? "-" : "+"}</div>
            </MenuTitle>
            {showAboutUsMenu ?
              DisplayMenuContent("aboutus") : ''}
          </SmallSectionHeader>
          <StyledLink to="account"><button>Account</button></StyledLink>
          <StyledLink to="product/ss"><button>Product</button></StyledLink>
        </Section>
      </Menu >
    )
  }


  return (
    <HamburgerMenu>
      <div onClick={event => {
        event.preventDefault()
        console.log("call toggle function")

        // pretend like we saved a record to the DB here
        // and then we navigate imperatively
        toggle("menu");

      }}>>=</div>
      {showMenu ? DisplayMenu() : ''}
    </HamburgerMenu>
  );
};

const HamburgerMenu = styled.div`
  @media (max-width: 360px) {
      width: 100%;
      min-height: 80vh;
      max-height: 100vh;
      padding-top: calc(20px *1);
  }
  @media (min-width: 360px) {
    min-width: 360px;
    max-width: 360px;
    min-height: 100vh;
    max-height: 100vh;
    // padding-top: 120px;
  }
  position: absolute;
  

`;


const Menu = styled.div`
  @media (max-width: 360px) {
      width: 100%;
      min-height: 80vh;
      max-height: 100vh;
      padding-top: calc(20px *1);
  }
  @media (min-width: 360px) {
    min-width: 360px;
    max-width: 360px;
    min-height: 100vh;
    max-height: 100vh;
    // padding-top: 120px;
  }
  position: absolute;
  padding: 20px;
  background-color: red;
  display: flex;
  flex-direction: column;

`;

const Section = styled.div`
  padding-top: calc(20px *1);
  padding-bottom: calc(20px *1);
  display: flex;
  flex-direction: column;
`;

const SectionHeader = styled.div`
  @media (max-width: 360px) {
    font-size: 30px;

  }
  @media (min-width: 360px) {
    font-size: 40px;
    // padding-top: calc(20px * 6);
  }
  text-transform: uppercase;
  padding-bottom: calc(20px *1);
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
`;

const SmallSectionHeader = styled(SectionHeader)`
  @media (max-width: 360px) {
    font-size: 25px
  }
  @media (min-width: 360px) {
    font-size: 20px
  }
`;

const MenuTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px;
`;

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  background-color: yellow;
  padding: 10px 20px;
`;

const StyledLink = styled(Link)`
  color: #000;
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  text-decoration: none;
`;

