import React, { useEffect, useState } from 'react';
import { RouteComponentProps, Link, navigate } from '@reach/router';
import styled from 'styled-components';
import Page from '../components/Page';
import Menu from '../components/Menu';
import Home from './home';

export default (props: RouteComponentProps) => {

  return <Page>
    <Menu />
    <Home />
  </Page>;
};