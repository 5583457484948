import React from 'react';
import { RouteComponentProps, Link, navigate } from '@reach/router';
import Page from '../components/Page';

export default (props: RouteComponentProps) => {
  return <Page>
      <div>
          <h1 className="">Not found</h1>
          <Link to="../"><button>Link type Back</button></Link>
          <button onClick={
              event => {
                      event.preventDefault()
                      console.log("back button is clicked")

                      // pretend like we saved a record to the DB here
                      // and then we navigate imperatively
                      navigate(-1)
                  }}
              >
                  Back</button>
      </div>
      </Page>;

};
