// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app';

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCe_WMBuKj3cxtLdYDdmfWKqqI1Lu16d-A',
  authDomain: 'sq-dev-f3fcf.firebaseapp.com',
  databaseURL: 'https://sq-dev-f3fcf.firebaseio.com',
  projectId: 'sq-dev-f3fcf',
  storageBucket: 'sq-dev-f3fcf.appspot.com',
  messagingSenderId: '1021090485630',
  appId: '1:1021090485630:web:1493e936a4e335310d8f2f',
  measurementId: 'G-VM8MYYM4G1',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
auth.useDeviceLanguage();
export const firestore = firebase.firestore();
export default firebase;
