import React, { ReactChild } from 'react';
import { UserProvider } from './user';

export const StoreProvider = ({
  children,
}: {
  children: ReactChild | ReactChild[];
}) => {
  return <UserProvider>{children}</UserProvider>;
};
