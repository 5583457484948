import React from 'react';
import { RouteComponentProps, Link } from '@reach/router';
import Page from '../components/Page';

export default (props: RouteComponentProps) => {
  return <Page><div>
    <div>Collection A</div>
    <Link to="../"><button>Link type Back</button></Link>
    </div></Page>;
};
