import React from 'react';
import { RouteProps } from '../types';
import { Link, navigate } from '@reach/router';
import Page from '../components/Page';

export default (props: RouteProps) => {
  return <Page>
    <div>
      <h1 className="">My account information</h1>
      <Link to="edit"><button>edit</button></Link>
      <Link to="../"><button>Back</button></Link>
      {props.children}
    </div>
  </Page>;
};
